<script setup lang="ts">
const props = defineProps<{
  code?: string;
  class?: string;
}>();

const { copy, copied } = useClipboard();
</script>

<template>
  <div class="relative">
    <pre
      :class="props.class"
    ><slot /></pre>
    <UButton
      class="absolute -right-2 -top-2 rounded-full border border-gray-200 dark:border-gray-600"
      variant="soft"
      color="gray"
      size="2xs"
      aria-label="Copy code to clipboard"
      :icon="!copied ? 'i-carbon-copy' : 'i-carbon-checkmark-outline'"
      @click.prevent="copy(code ?? '')"
    />
  </div>
</template>

<style>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

pre code .line {
  display: block;
  min-height: 1rem;
}
</style>
