<script setup lang="ts">
import type { PropType } from "vue";

const props = defineProps({
  href: {
    type: String,
    default: "",
  },
  target: {
    type: String as PropType<"_blank" | "_parent" | "_self" | "_top" | null | undefined>,
    default: undefined,
    required: false,
  },
});

function handleClick() {
  window.open(props.href, props.target ?? "_blank");
}
</script>

<template>
  <NuxtLink
    :href="href"
    :target="target ?? '_blank'"
    @click.prevent="handleClick"
  >
    <slot />
  </NuxtLink>
</template>
